import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { navigate } from '@reach/router';

import { CONTACT_INFO } from '@app/constants';
import { useLocale } from '@app/hooks';
import { Layout, Page as P, Seo, Spinner } from '@app/components';
import Head from 'components/Page/Head';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const SCOPE_OPTIONS = {
    scope: 'pages.contact',
  };
  const { t, language } = useLocale();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (data: any) => {
    setIsLoading(true);

    const mutateData = {
      fullName: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
      website: 'clinicsbbinternational',
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(mutateData));

    await axios({
      method: 'POST',
      url: 'https://sbb-panel.herokuapp.com/contact-forms',
      data: formData,
    }).then(() => {
      navigate(`/${language}/thanks`);
      setIsLoading(false);
    });
  };

  return (
    <Layout>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <P.Head
        title={t('labels.contact', SCOPE_OPTIONS)}
        date="February 5, 2022"
        breadcrumb={[
          { path: '/', name: 'Home' },
          {
            path: '/contact',
            name: t('labels.contact', SCOPE_OPTIONS),
          },
        ]}
      />
      <div className="container mx-auto px-4 py-10">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="w-full lg:w-1/3">
            <h4 className="text-xl font-medium text-primary border-b pb-4 mb-10 font-poppins border-b-1 border-primary">
              {t('labels.contactUs', SCOPE_OPTIONS)}
            </h4>
            <div className="">
              <div className="col-span-12 lg:col-span-3 text-sm">
                <div>
                  <FontAwesomeIcon className="mr-3 mt-8 " icon={faPhone} size="xl" />
                  <a className="text-xl mt-1" href={`tel:${CONTACT_INFO.PHONE}`}>
                    {CONTACT_INFO.PHONE}
                  </a>
                </div>

                <div className="flex mt-5">
                  <FontAwesomeIcon icon={faEnvelope} size="2xl" className="mt-1" />
                  <a href={`mailto:${CONTACT_INFO.MAIL}`} className="mx-2 text-xl mt-1">
                    {CONTACT_INFO.MAIL}
                  </a>
                </div>

                <div className="flex mt-5">
                  <FontAwesomeIcon icon={faLocationDot} size="2xl" className="mt-1" />
                  <a href={`mailto:${CONTACT_INFO.MAPFORLINK}`} className="mx-2 text-xl">
                    {CONTACT_INFO.ADDRESS}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/3">
            <div>
              <div className="mt-5">
                <iframe
                  src={CONTACT_INFO.MAP}
                  height="435"
                  className="w-full border-0"
                  allowFullScreen
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
